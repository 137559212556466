const { isFunction: isFunction_ } = require('lodash')
const { isPlainObject: isPlainObject_ } = require('lodash')
const { mapValues: mapValues_ } = require('lodash')
const noop = () => {}

const wrap = (obj, before = noop, after, path = []) => {
  return mapValues_(obj, (val, key) => {
    if (isFunction_(val)) {
      const fullName = path.concat(key).join('.')
      return (...args) => {
        before(fullName, args)
        const originalResult = val(...args)
        return after ? after(fullName, originalResult) : originalResult
      }
    } else if (isPlainObject_(val)) {
      path.push(key)
      const newVal = wrap(val, before, after, path)
      path.pop()

      return newVal
    }

    return val
  })
}

module.exports = wrap
