import {
  createI18nFromAppInfo,
  getAppUrl,
  getAppVersion,
} from '@wix/wix-data-client-common-standalone'
import {
  APP_NAME,
  CLOUD_DATA_URL,
  LOCALE_FALLBACK_URL,
  LOCALE_FOLDER,
  STUDIO_LOCALES_FOLDER,
  EXPERIMENTS_HOST,
  EXPERIMENTS_SCOPE,
  DATA_CLIENT_EXPERIMENTS_SCOPE,
} from '@wix/wix-data-client-common'
import dbsmEnglishTranslations from '@wix/dbsm-locales'
import studioEnglishTranslations from '@wix/studio-locales'
import { WixDataSettings } from '@wix/wix-data-settings-client'
import { WixDataConnectorSettings } from '@wix/wix-data-connector-settings-client'
import Experiments from '@wix/wix-experiments'
import { create as createFedopsLogger } from '@wix/fedops-logger'
import manifests from '@wix/wix-code-data-type-manifests'
import createWixData from '@wix/wix-data-client-common/dist/esm/infra/createWixData'
import { createDataSchemasClientForBrowser } from '@wix/wix-data-schemas-client'

import commonModule from './modules/common'
import { coreModule, createWorker } from '@wix/dbsm-editor-worker'
import dataBindingModule from '@wix/wix-data-client-data-binding/dist/esm/worker'
import dynamicPagesModule from '@wix/wix-data-client-dynamic-pages/dist/esm/worker'
import schemaManagementModule from '@wix/wix-data-client-schema-management/dist/esm/worker'

const experimentsManager = new Experiments({
  scopes: [EXPERIMENTS_SCOPE, DATA_CLIENT_EXPERIMENTS_SCOPE, 'components'],
  baseUrl: EXPERIMENTS_HOST,
})

const i18nInstance = createI18nFromAppInfo({
  appName: APP_NAME,
  appVersion: getAppVersion(getAppUrl(APP_NAME)),
  localeFolder: LOCALE_FOLDER,
  fallbackUrl: `${LOCALE_FALLBACK_URL}/${LOCALE_FOLDER}`,
  englishTranslations: dbsmEnglishTranslations,
  onFallback: undefined,
  getExperiments: async () => {
    await experimentsManager.ready()
    return experimentsManager.all()
  },
})

const i18nStudioInstance = createI18nFromAppInfo({
  appName: APP_NAME,
  appVersion: getAppVersion(getAppUrl(APP_NAME)),
  localeFolder: STUDIO_LOCALES_FOLDER,
  fallbackUrl: `${LOCALE_FALLBACK_URL}/${STUDIO_LOCALES_FOLDER}`,
  englishTranslations: studioEnglishTranslations,
  getExperiments: async () => {
    await experimentsManager.ready()
    return experimentsManager.all()
  },
})

const FEDOPS_ID = 'wix-code-data-binding-editor-worker'
const fedopsLogger = createFedopsLogger(FEDOPS_ID)
const cloudDataUrl = CLOUD_DATA_URL
const loadDataTypeManifests = () => Promise.resolve(manifests)

const createWixDataSchemas = ({ httpClient, gridAppId, instance }) =>
  createDataSchemasClientForBrowser(httpClient, instance, gridAppId, {
    baseUrl: cloudDataUrl,
    useApiV2: true,
  })

const worker = createWorker({
  hostname: self.location.host,
  i18nInstance,
  i18nStudioInstance,
  experimentsManager,
  fedopsLogger,
  loadDataTypeManifests,
  WixDataSettings,
  WixDataConnectorSettings,
  cloudDataUrl,
  createWixData,
  createWixDataSchemas,
  moduleExports: [
    commonModule,
    coreModule,
    dataBindingModule,
    dynamicPagesModule,
    schemaManagementModule,
  ],
})

export const editorReady = worker.editorReady
export const exports = worker.exports
export const getAppManifest = worker.getAppManifest
export const getControllerPresets = worker.getControllerPresets
export const handleAction = worker.handleAction
export const onEvent = worker.onEvent
