const { isFunction: isFunction_ } = require('lodash')
const { isPlainObject: isPlainObject_ } = require('lodash')
const { mapValues: mapValues_ } = require('lodash')

const bindObjectFunctions = (obj, ...args) => {
  return mapValues_(obj, val => {
    if (isFunction_(val)) {
      return val.bind(obj, ...args)
    } else if (isPlainObject_(val)) {
      return bindObjectFunctions(val, ...args)
    }

    return val
  })
}

module.exports = bindObjectFunctions
