const { assign: assign_ } = require('lodash')
const { mapKeys: mapKeys_ } = require('lodash')
const TEXT_ROLE = 'textRole'

module.exports = (config, role) => {
  if (role === TEXT_ROLE && config && config.properties) {
    return assign_({}, config, {
      properties: mapKeys_(config.properties, (_, property) =>
        property === 'html' || property === 'text' ? '$text' : property,
      ),
    })
  }
  return config
}
