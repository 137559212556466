const { isString: isString_ } = require('lodash')
const { words: words_ } = require('lodash')
const { deburr: deburr_ } = require('lodash')
const { capitalize: capitalize_ } = require('lodash')
const { trim: trim_ } = require('lodash')
const { flow: flow_ } = require('lodash')

const sanitizeFieldKey = (value, flags = {}) =>
  isString_(value)
    ? flow_([
        trim_,
        flags.allowLeadingDigits ? s => s : removeLeadingDigits,
        sanitizeString(flags),
      ])(value)
    : ''

const sanitizeString = flags => string =>
  string.replace(
    flags.allowHyphens ? /[^-_]+/g : /[^_]+/g,
    sanitizePiece(flags),
  )

const sanitizeWord = (word, index) =>
  stripNonAsciiAlphaNumeric(deburr_(transformWordToCamelCase(word, index)))

const sanitizePiece = flags => string =>
  words_(string)
    .map(flags.mapWord ? flags.mapWord : sanitizeWord)
    .join(flags.wordSeparator || '')

const transformWordToCamelCase = (word, index) =>
  index > 0 ? capitalize_(word) : word.toLowerCase()

const stripNonAsciiAlphaNumeric = string => string.replace(/[^\dA-Za-z]/g, '')

const removeLeadingDigits = string => string.replace(/^\d+/, '')

module.exports = sanitizeFieldKey
