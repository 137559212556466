const { trim: trim_ } = require('lodash')
const wrapObjectFunctions = require('@wix/wix-data-client-dbsm-common/src/wrapObjectFunctions')
const isPromise = require('@wix/wix-data-client-dbsm-common/src/isPromise')

const requestBreadcrumb = (fnName, args) => ({
  category: 'editorSDK Request',
  message: `${fnName}(${trim_(JSON.stringify(args), '[]')})`,
})

const responseBreadcrumb = (fnName, value) => ({
  category: 'editorSDK Response',
  message: `${fnName} -> ${JSON.stringify(value)}`,
})

const errorBreadcrumb = (fnName, error) => ({
  category: 'editorSDK Error',
  message: `${fnName} -> ${error.stack || JSON.stringify(error)}`,
})

const eventBreadcrumb = (eventType, eventPayload) => ({
  category: 'editorSDK Event',
  message: `Type: ${eventType}. Payload: ${JSON.stringify(eventPayload)}`,
})

const wrapWithBreadcrumbs = ({ editorSDK, onBreadcrumb }) => {
  const beforeApiCall = (fnName, args) =>
    onBreadcrumb(requestBreadcrumb(fnName, args))

  const afterApiCall = (fnName, promiseOrValue) => {
    if (!isPromise(promiseOrValue)) {
      onBreadcrumb(responseBreadcrumb(fnName, promiseOrValue))
      return promiseOrValue
    }

    return Promise.resolve(promiseOrValue).then(
      value => {
        onBreadcrumb(responseBreadcrumb(fnName, value))
        return value
      },
      error => {
        onBreadcrumb(errorBreadcrumb(fnName, error))
        return Promise.reject(error)
      },
    )
  }

  const wrappedApi = wrapObjectFunctions(editorSDK, beforeApiCall, afterApiCall)

  const wrappedOnEvent = {
    onEvent: cb =>
      editorSDK.panel.onEvent(({ eventType, eventPayload }) => {
        onBreadcrumb(eventBreadcrumb(eventType, eventPayload))
        // eslint-disable-next-line
        cb({ eventType, eventPayload })
      }),
  }

  wrappedApi.panel = Object.assign({}, wrappedApi.panel, wrappedOnEvent)

  return wrappedApi
}

module.exports.wrapWithBreadcrumbs = wrapWithBreadcrumbs
