import { openGenericConfirmationPanel } from '@wix/wix-data-client-common'

export default ({ editorSdkProxy, i18n, logger }) => {
  return {
    openErrorPanel: ({ title, text, buttonLabel } = {}) =>
      openGenericConfirmationPanel({
        editorSdkProxy,
        t: i18n.t.bind(i18n),
        logger,
        headerText: title,
        descriptionText: text,
        mainActionText: buttonLabel,
      }).catch(logger.error),
  }
}
