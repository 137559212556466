const { merge: merge_ } = require('lodash')
const { assign: assign_ } = require('lodash')
const { reduce: reduce_ } = require('lodash')
const { forOwn: forOwn_ } = require('lodash')
const { get: get_ } = require('lodash')
const { set: set_ } = require('lodash')

const connectionConfigFixersHandlers = {
  'controllers.listConnections': connectionConfigFixers => connections => {
    const fixConnectionConfig = (config, role) =>
      reduce_(
        connectionConfigFixers,
        (updatedConfig, fixer) => fixer(updatedConfig, role),
        config,
      )
    return connections.map(connection =>
      assign_({}, connection, {
        config: fixConnectionConfig(connection.config, connection.role),
      }),
    )
  },
}

const wrapMethodWithDataFixers = (getterToWrap, fixersHandler, dataFixers) => {
  const fixData = fixersHandler(dataFixers)
  return (...args) => getterToWrap(...args).then(fixData)
}

module.exports = (editorSDK, { connectionConfigsFixers = [] }) => {
  const wrapperMap = {}

  const addDataFixerWrappers = (fixersHandlers, dataFixers) => {
    if (dataFixers.length) {
      forOwn_(fixersHandlers, (fixersHandler, pathToGetter) => {
        const getterToWrap = get_(editorSDK, pathToGetter)
        const wrappedMethod = wrapMethodWithDataFixers(
          getterToWrap,
          fixersHandler,
          dataFixers,
        )
        set_(wrapperMap, pathToGetter, wrappedMethod)
      })
    }
  }

  addDataFixerWrappers(connectionConfigFixersHandlers, connectionConfigsFixers)

  return merge_({}, editorSDK, wrapperMap)
}
