const BEHAVIOR_TYPES = {
  SAVE_FAILURE_FEEDBACK: 'saveFailureFeedback',
  SAVE_SUCCESS_FEEDBACK: 'saveSuccessFeedback',
}

const BEHAVIORS = {
  [BEHAVIOR_TYPES.SAVE_FAILURE_FEEDBACK]: {
    name: BEHAVIOR_TYPES.SAVE_FAILURE_FEEDBACK,
    label: 'Connect_Panel_Form_Messages_Option_Submit_Fail',
    textElement: `<p class="font_8"><span style="color:#FF4040">An error occurred. Try again later</span></p>`,
  },
  [BEHAVIOR_TYPES.SAVE_SUCCESS_FEEDBACK]: {
    name: BEHAVIOR_TYPES.SAVE_SUCCESS_FEEDBACK,
    label: 'Connect_Panel_Form_Messages_Option_Submit_Success',
    textElement: `<p class="font_8">Your content has been submitted</p>`,
  },
}

// TODO use these in editor/viewer apps
module.exports = {
  BEHAVIORS,
  BEHAVIOR_TYPES,
}
